import { Thought } from "."

export const PapasBeltThought: Thought = {
  path: "i_wear_papas_belt",
  title: "I wear Papa's belt",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <p>
          I was away at college for most of the time Papa, my Grandpa, was sick.  My experience of his aging was concentrated to the summers and breaks when I lived in my Grandparent's extra bedroom. The whiplash of transitioning between a college dorm and a home centered around making Papa's last days enjoyable was not entirely lost on me, but in my attempt to stay out of the way I don't have many memories from this time. One summer, I needed a black belt that I didn't own so my Grandma reached into Papa's closet and pulled out his black leather belt. She offered it to me and he insisted that I keep it as it wouldn't go with his pajamas and robes.
        </p>

        <p>
          A year later, while the snow crunched under my boots in the Minnesota forest Papa took his last breath. I wish I could say his belt was hugging my waist, holding my shape, allowing us to share that space together; if I was wearing his belt that day it was not intentional.
        </p>

        <p>
          Slowly, over time, Papa's belt became a symbol for him and the space he inhabited. I do my best to share his gentle and self deprecating demeanor while wearing his belt; he was a joy to those around him. Some mornings while I slip the cracked leather through my pant loops, I wonder if he knew what his belt would mean to me when he said I could have it.
        </p>

        <p>
          I love you Papa.
        </p>
      </div>
    )
  }
}
