import { Thought } from "."

export const SeasonalPlaylistsThought: Thought = {
  path: "seasonal_playlists",
  title: "My seasonal playlists",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <p>
          Over the past 6 years, I have made a playlist for each season Winter, Spring, Summer, and Fall.
          The songs on the playlists are played so frequently that if I listen to an old playlist, memories of that time in my life surface.
          I like to imagine passing these playlists along to the young people in my life as they reach the age I curated each.
          Even though these playlists bring up many associations for me, I am not disillusioned into thinking anyone but me will enjoy the collection.
          My contribution is the collection but the individual songs, which I can claim no credit to, are what might resonate.
        </p>
        <p>
          At the end of the year I compile a playlist with the 10 songs from those playlists that remind me the most of that year. 
          The following is the consolidation of the 4 seasonal playlists from 2022 into 10 songs.
          Music is personal and at the end of the day these playlists are for me, but perhaps they will do something for others too. 
        </p>
        <iframe style={{borderRadius:"12px"}} src="https://open.spotify.com/embed/playlist/4CNR7xQexQvZ0aex1hLqqa?utm_source=generator" width="100%" height="352" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      </div>
    )
  }
}
