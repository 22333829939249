import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import './app.css'
import { Home } from './home'
import { AllThoughts, Thoughts } from './thoughts'
import Vis1 from './visuals/vis1'

export function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/thoughts">
            <Route index element={<Thoughts/>} />
            {AllThoughts.map(function(thought) {
              return <Route path={thought.path} element={thought.content()}/>
            })}
          </Route>
          <Route path="/visuals">
            <Route index element={
              <Vis1 cx={50} cy={50} r={30} fill="#9370DB" stroke="" strokeWidth={0} />
            } 
            />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  )
}

function displayHome() {
  return useLocation().pathname != "/"
}

function displayThoughts() {
  return useLocation().pathname.includes("/thoughts/")
}

const Layout = () => {
  return (
    <div>
      <ul>
        <li>
          {displayHome() && <Link className='link' to='/'>Home</Link>}
        </li>
        <li>
          {displayThoughts() && <Link className='link' to='/thoughts'>{'> '}Thoughts</Link>}
        </li>
      </ul>
      <Outlet/>
    </div>
  )
}

const NoMatch = () => {
  return (
    <div>
      I hope you find what you are looking for. It isn't here.
    </div>
  )
}
