import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import data from "../visuals/santa_cruz_streets.json"

function Squiggle(props) {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    const margin = 20
    const width = props.width
    const height = props.height

    const streets = data
    console.log(streets)

    const viennaProjection = d3.geoMercator().fitExtent([[margin, margin], [width - margin, height - margin]], streets)
    const viennaPathGenerator =  d3.geoPath().projection(viennaProjection)

    const svg = d3.select('svg')
    svg.selectAll('path')
      // data() expects an Array, so make sure to pass the features entry of our FeatureCollection
      .data(streets.features)
      // select all data items that are not represented on the map yet, and add them
      .enter()
      .append('path')
      // assign attributes to those new elements
      .attr('d', viennaPathGenerator)
      .attr('fill', 'none')
      .attr('stroke', '#9370DB')
      .attr('stroke-width', '0.8')
      .on('mouseover', function (d) {
        d3.select(this).attr('stroke-width', '5.6')
        console.log(d.toElement.__data__.properties)
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('stroke-width', '0.8')
      })
  }, [props]);


  return (
    <svg width={props.width} height={props.height} ref={ref} />
  );
}

function Vis1(props) {
  return (
    <Squiggle width={1000} height={1000} />
  );
}

export default Vis1;
