import { Thought } from "."

export const BestDayThought: Thought = {
  path: "best_ways_to_spend_a_day",
  title: "My favorite ways to spend a day",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <ul>
          <li>- Building a sledding course</li>
          <li>- Floating down a river</li>
          <li>- Building a tree net</li>
          <li>- Bike parting with friends</li>
          <li>- Doing puzzles and playing board games</li>
          <li>- Exploring a new path</li>
        </ul>
        <p>
          While these activities are guaranteed to put a smile on my face,
          the most important thing is to set myself up for a day where all logistics are taken care of and I can just play.
        </p>
      </div>
    )
  }
}
