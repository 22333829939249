import { Thought } from "."

export const ThingsThatCarryMeThought: Thought = {
  path: "things_that_carry_me",
  title: "The things that carry me",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <p>
          I have barely started Ross Gay's book of delights, but am inspired to start on my own. He challenged himself to write daily on the delights in his life and the book is a collection of those essays. The premise is simple but the content is elegant, reflective, and curious in ways that my own wouldn't live up to. 
        </p>
        <p>
          My 11th grade English teacher assigned us a project that required us to write 300 words per week. It didn't matter what it was, we just had to hit that number and turn it in. I remember what it felt like to sit at my computer to type without consequences at a time when every written word would be assigned a grade. Neglecting the backspace key wasn't intuitive to me and certainly still isn't, but it got easier with practice. I'd squirm at the thought of handing those assignments over to my friends or even worse, the internet. Yet, my teacher read and commented on every last one of them in a way that made me feel heard and appreciated. Maybe that is what I'll dream for: consistency and a small supportive audience. 
        </p>
        <p>
          A corner of the internet like this page contains endless possibility that paralyzes me in fear of failure. Before the page is marked I plead myself not to waste the world's infinite canvas on content that is small and devoid of creativity. "Do not fail," I tell myself on every blank page.
        </p>
        <p>
          Why I've loved the Book of Delights so far is it takes part in my favorite activity: sharing what is interesting. No matter what he says, I am with him because I know it is something that he cares about. I believe that an attempt to share what is motivating, capturing, or challenging is meaningful because it gives us an opportunity to connect over experience.
        </p>
        <p>
          The dream is modest and achievable: for this to be a vault of the things that carry me. These are the thoughts and ideas that take me through the infinite creative canvas to places I want to go. These are some of my favorite things to talk about and that alone makes them worth sharing.
        </p>
      </div>
    )
  }
}
