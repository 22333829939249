import { Thought } from "."

export const FindPlayOutsideThought: Thought = {
  path: "finding_play_outside",
  title: "Finding play outside",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <p>
          One of my priorities in February has been to find play outside. In the past this has included bodysurfing, building a treenet, mountain biking, floating rivers and sledding. I won't pretend to ignore that Irvine is within driving distance from world class bodysurfing and mountain biking, but for some reason my usual playtime activities haven't sparked me since moving. 
        </p>
        <p>
          When I moved to small town Minnesota for my freshman year of college, carving on my skateboard was a source of creative expression that regularly left me grinning. Peak homesickness hit me hard on a crisp fall night my freshman year. I put on "For Emma, Forever Ago" and skated through the neighborhoods of Northfield; allowing myself to feel the cold air, see the shadows under the streetlights, and hear the sounds of one of my favorite albums. Skateboarding forces me to notice the potholes, curbs, and hills of my environment and encourages me to weave between, go down, and slide over them. By focusing on and playing with these details, skateboarding strengthens my connection to my environment. I returned to my dorm, not cured but content and more present than before. 
        </p>
        <p>
          On a recent day of long anticipated FOMO, I needed an extra dose of play. My strategy was to go for a long bike ride hoping to feel a sense of accomplishment after covering a lot of ground. Even before touching my bike, I knew this wouldn't be enough so I told myself to avoid focusing on a particular mileage outcome but be present and look for play. Art isn't created in a museum, instead it is appreciated and restored. That ride for me was a trip through a museum of play, I was appreciating and noticing play but not actively taking part in it. Who created the overgrown trail cut into the hills at the end of a cul-de-sac of mansions? When was the word "tits" carved into the sidewalk of the otherwise pristine park? My eyes lingered on the details in my neighborhood, especially the smoothness of the concrete.
        </p>
        <p>
          The bike ride around my neighborhood showed me an expanse of smooth concrete unlike any I've lived in before. It reminded me of skating around Northfield, the play and comfort brought to me by exploring. I got home and traded in my bike for my skateboard, and for the first time in years I went skating. There was so much to see and play with in places I'd frequented but this time I had a new appreciation for the hills, curbs, and bumps in front of me. 
        </p>
        <p>
          I found play in skating and the next day I purchased rollerblades and pads to remind myself that play and exploration should always be a priority.
        </p>
      </div>
    )
  }
}
