import { Thought } from "."

export const MonthOfRollerbladingThought: Thought = {
  path: "1_month_of_rollerblading",
  title: "1 month of rollerblading",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <p>
          After 1 month of skating I can:
        </p>
        <ul>
          <li>- stop with some reliability</li>
          <li>- step over turn both directions</li>
          <li>- control my speed down the freeway overpass hill</li>
          <li>- spin in a big circle either direction</li>
          <li>- skate backwards</li>
          <li>- jump down a curb</li>
          <li>- jump up a curb</li>
          <li>- spin to the right 180 degrees and continue backward (slowly)</li>
          <li>- jump turn 180 and continue backward (slowly)</li>
        </ul>
        <p>
          None of these things were easy when I started, some of them are still difficult. Skating has gotten me out of the house regularly and is consistently the activity that brings me the most joy. 
        </p>
      </div>
    )
  }
}
