import { Thought } from "."

export const BojackThought: Thought = {
  path: "bojack_horseman",
  title: "Everything is worse now: Bojack Horseman",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <p>
          We watched the Eulogy "Free Churro" Episode (Season 5 Episode 6), and it absolutely floored me.
          It is the kind of episode that makes me want to talk about it with everyone I know, but as a recommendation it falls flat.
        </p>
        <p>
          You can't tell someone to go watch that episode because the reason it is so good is that it is set up so well by the character development over the preceeding episodes. 
          Instead, you can say <b>"Go watch 4 Seasons and 5 episodes of Bojack Horseman just so you can watch this episode"</b>. 
          I plan to do just that, it was honestly that good.
        </p>
      </div>
    )
  }
}
