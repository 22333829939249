import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className="container">
      <h1>
        macallan's website
      </h1>

      <p>I am human who likes building systems. I currently do this full time as a programmer.</p>
      <p>
        "The programmer necessarily sees the world as suffering from bad software: better coding makes for happier people." 
        - <a 
        href="https://medium.com/team-human/you-are-not-alone-you-are-on-team-human-4457a0a35615#:~:text=The%20programmer%20necessarily%20sees%20the,approaches%20address%20only%20granular%20problems.">
          Douglas Rushkoff
          </a>
      </p>

      <p>
        Growing up I believed that programming is how I would <i>make a difference</i>.
        Rushkoff points out that better coding doesn't necesarily make for happier people.
        When I read some version of that quote in Team Human, I lost some gumption for my chosen career and my <i>passion</i> turned into a job.
        I wondered how my efforts were helping or exacerbating various problems. 
        So I stopped programming (at least in my free time) and started reading to learn and listen.
      </p>

      <p>
        Like many people I talk to, I find practicing self acceptance to be an ongoing challenge. 
        Over the past few years I've become more comfortable with the idea that I'll make a difference in ways other than writing code.
        I will prioritize the daily interactions with family, friends, and coworkers over the code that I write.
        The world will change if I focus on what is important: valuing other people and the spaces we inhabit.
        That frees up programming to be something I do for a job, hobby, and most importantly myself. 
        I still love building systems and the feeling of calm that comes from structuring and organizing.
        This is why I am excited about programming.
      </p>
    
      <p>
        So what is next? 
        I don't know.
        I am appreciating the ambiguity and the humanity that comes with the uncertainty.
        I plan to keep learning, listening, and having fun.
        I'd like to also be proactive in sharing what I am <Link to="/thoughts">thinking about</Link>.
      </p>

      <p>
        If you have ideas on what I should do next, please reach out. 
        
        macallan(at)perabrown(dot)com
      </p>
    </div>
  );
};
