
  import { Thought } from "."

  export const MagicatDisneyland: Thought = {
    path: "magic_at_disneyland",
    title: "Magic at Disneyland",
    content: function Content() {
      return (
        <div className="container">
          <h1>{this.title}</h1>
          <p>Our friend gave us free tickets to Disneyland yesterday. The last time I was there I think I was 6 or 7, and although the curtain that hides the magic was lifted, I was still buzzing the whole day. So many hilarious kids, coordinated group outfits and tiny details to take in. Perhaps overstimulating at times but I can't say enough about the level of commitment that goes into making that experience possible from attendees to the cast to the physical space, everyone has bought in. After our last ride we stumbled upon a beautiful moment in cars land. Everyday at sunset a group gathers in the middle of the intersection waiting for the lights from the buildings and the streets to come on. At the designated time the lights come on, music starts playing, and everyone dances together in a big circle. It was beautiful. Since then, I've been wondering why we are so willing to commit to experiencing magic at Disneyland. </p>
          <p></p>
          <p>I've been jokingly calling the Santa Ana airport Disneyland's Hangover and this morning I understand why. I am exhausted. Can't imagine how the kids and parents feel.</p>
        </div>
      )
    }
  }
  