import { Thought } from "."

export const MidnightGospelThought: Thought = {
  path: "the_midnight_gospel",
  title: "It breaks your heart open: The Midnight Gospel",
  content: function Content() {
    return (
      <div className="container">
        <h1>{this.title}</h1>
        <p>
          The last episode of Season 1 of the Midnight Gospel is one of my favorite recommendations. 
        </p>
        <p>
          The premise of Midnight Gospel is unique and wasn't immediately clear to me after watching the first episode. 
          All I could say was "They are having a conversation", to my friend as we sat on the couch a bit too stoned to communicate effectively. 
          Duncan Trussell, the creator, has a podcast called The Duncan Trussell Family Hour where he interviews guests on a wide variety of topics (drugs, life, death, religion). 
          The midnight Gospel takes pieces of these podcast episodes and animates over them with dreamy, psychedelic visuals. 
          The stylistic resemblance to Adventure Time is no coincidence as the other co creator of the series is Pendleton Ward. 
          While the visuals are stunning and probably deserve more thought to grasp the intention behind them, the highs and lows of the series hedge on its conversational tone.
        </p>
        <p>
          At times it can feel dense and difficult to keep up with the conversation especially when attempting to parse how the visuals relate to what is being said. 
          However, in its final episode the potential of the format is highlighted as we get to experience one of the most vulnerable and personal episodes in television. 
          I love recommending this episode because it can stand alone without losing too much from skipping the preceding episodes. 
        </p>
        <p>
          In the final episode, Duncan interviews his mother 2 weeks before she dies of cancer. 
          It is beautiful, wise, and a deeply personal ode to his mother. 
          Everyone should watch it. 
        </p>
      </div>
    )
  }
}
