import { Link } from "react-router-dom";
import { BestDayThought } from "./bestDayThought";
import { BojackThought } from "./bojackThought"
import { FindPlayOutsideThought } from "./findPlayOutsideThought";
import { MidnightGospelThought } from "./midnightGospelThought";
import { MonthOfRollerbladingThought } from "./monthOfRollerbladingThought";
import { PapasBeltThought } from "./papasBeltThought";
import { SeasonalPlaylistsThought } from "./seasonalPlaylistsThought";
import { ThingsThatCarryMeThought } from "./thingsThatCarryMeThought";
import { MagicatDisneyland } from "./magicatDisneylandThought";

export interface Thought {
  content: (() => JSX.Element)
  path: string
  title: string
}

export const AllThoughts = [
  MagicatDisneyland,
  MonthOfRollerbladingThought,
  FindPlayOutsideThought,
  SeasonalPlaylistsThought,
  PapasBeltThought,
  ThingsThatCarryMeThought,
  BestDayThought,
  MidnightGospelThought,
  BojackThought,
]

export function Thoughts() {
  return (
    <div className="container">
      <h1>Thoughts</h1>

      <ul>
        <li>
          {AllThoughts.map(function (thought) {
            return <div><Link key={thought.title} to={thought.path}>{thought.title}</Link></div>
          })}
        </li>
      </ul>
    </div>
  )
}
